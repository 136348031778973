// Row
@include media-breakpoint-up(xl) {
  .row-cols-xl-7 > * {
    flex: 0 0 auto;
    width: 14.285%;
  }
}

.container {
  &,
  &-xxl {
    max-width: 1430px;
  }
}

// Lozad
.lozad.fade {
  opacity: 1;
  transition: opacity 0.15s ease-out;
  &:not([data-loaded="true"]) {
    opacity: 0;
  }
}

.link-dotted {
  text-decoration: none;
  border-bottom: 1px dotted;
  @include transition($input-transition);
  &:hover {
    border-color: transparent;
  }
}

.nav-underline {
  .nav-link {
    margin: 0 0.75rem;
  }
  .custom-link {
    display: block;
    text-decoration: none;
    padding-bottom: 0.75rem;
  }
  .nav-link,
  .custom-link {
    position: relative;
    border-bottom: 0;

    &:hover {
      text-decoration: none;
    }
    @include media-breakpoint-up(lg) {
      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0.375rem;
        left: 50%;
        width: 0;
        opacity: 0;
        transition: left 0.25s, width 0.25s, opacity 0.5s;
        height: 0;
        border: 1px solid;
      }
      &:hover::before,
      &:focus::before {
        left: 0;
        width: 100%;
        opacity: 1;
        transition: left 0.25s, width 0.25s, opacity 0.05s;
      }
    }
  }
}

.shadow-hover {
  @include transition($navbar-toggler-transition);
  &:hover {
    box-shadow: $box-shadow-hover !important;
  }
}

// Slider products
.carousel {
  margin: 0 -16px -200px;
  padding: 16px 16px 200px;
  &:hover {
    z-index: 2;
  }

  .s-products {
    .swiper-slide {
      transition: opacity 0.3s;
      opacity: 0;
      pointer-events: none;
      &.swiper-slide-visible {
        opacity: 1;
        pointer-events: all;
      }
      &.swiper-slide-prev {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .swiper-container-horizontal {
    overflow: visible;
  }
}

// Card
.card-date {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  .icon {
    font-size: 14px;
  }
}

.card-link {
  line-height: 1;
  text-decoration: none;
  border-bottom: 1px dotted;
  @include transition($btn-transition);
  &:hover {
    border-color: transparent;
  }
}

// Pagination
.page-link svg {
  margin-top: -3px;
}

.clearfix {
  @include clearfix;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
  .nav,
  .breadcrumb {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
}
