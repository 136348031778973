.s-thumbnail {
  @include media-breakpoint-up(xl) {
    &__thumbs {
      width: 80%;
    }
    &__images {
      width: 20%;
    }
  }
  &__image {
    text-align: center;
  }
}

.img-fluid-height {
  width: auto;
  max-height: 100%;
}

.custom-tabs {
  .nav-link {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: 400;
    font-size: 14px;
    color: $gray-900;
    line-height: 15px;

    &.active {
      font-weight: bold;
      text-decoration: underline;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.product-price {
  line-height: 1;
  &__old {
    font-size: 15px;
    text-decoration: line-through;
  }
  &__main {
    font-weight: 800;
    font-size: 22px;
    color: $primary;
  }
}

.btn-product-link {
  display: inline-flex;
  align-items: center;
  font-weight: 300;
  font-size: 13px;
  color: $gray-900;
  text-decoration: none;

  .icon {
    margin-top: -2px;
    margin-bottom: -2px;
    margin-right: 8px;
    width: 21px;
    height: 21px;
    font-size: 21px;
    color: $primary;
    line-height: 21px;
    text-align: center;
  }
}

.product-info {
  &__title {
    font-weight: 600;
    font-size: 16px;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      margin-top: 4px;
      font-weight: 300;
      font-size: 13px;
    }
  }
  &__icon {
    fill: $primary;
    width: 1.25em;
    margin-right: 0.5rem;
  }
  &__img {
    margin-right: 0.75rem;
  }
}

.table-specification {
  td {
    padding: 0.5rem 1rem;
    a {
      border-bottom: 1px dotted $gray-600;
      font-weight: 600;
      color: $gray-600;
      text-decoration: none;
      transition: border 0.2s;
      &:hover {
        border-color: transparent;
      }
    }
  }
  td:first-child {
    width: 65%;
    color: $gray-600;
  }
}

@include media-breakpoint-up(xl) {
  .product-column-space {
    padding-right: 70px;
  }
}

.btn-wicon {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
