// Variables
$enable-caret: false;
$enable-rounded: false;

// Colors
$primary: #1a75cf;
$secondary: #131313;
$gray-100: #f9f9f9;
$gray-300: #e9e9e9;
$gray-800: #333333;
$gray-900: #131313;
$light: $gray-100;

// Ratio
$min-contrast-ratio: 2;

// Fonts
$font-family-sans-serif: "Mulish", sans-serif;
$font-size-base: 0.875rem;

$h1-font-size: 1.675rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.375rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1.125rem;
$h6-font-size: 1rem;

// Button
$input-btn-padding-y: 0.625rem;
$input-btn-padding-x: 1.5rem;
$btn-padding-y: 0.5rem;
$btn-padding-x: 2.5rem;
$btn-padding-y-lg: 0.625rem;
$btn-padding-x-lg: 2rem;
$btn-font-weight: 500;
$btn-line-height: 1;
$input-line-height: 1.25;

// Breadcrumb
$breadcrumb-margin-bottom: 0;
$breadcrumb-font-size: 13px;
$breadcrumb-item-padding-x: 0.625rem;

// Input
$input-padding-x: 1rem;
$form-label-font-weight: 700;
$input-bg: $light;
$input-border-color: $light;
$input-font-weight: 300;
$input-color: #000;

$input-placeholder-color: #000;

// Modal
$modal-content-border-radius: 1rem;

// Card
$card-border-radius: 0;
$card-img-overlay-padding: 0;
$card-border-color: $gray-100;
$card-title-spacer-y: 0.625rem;

// Shadow
$box-shadow: 0px 0px 0.675rem 0px rgba(0, 0, 0, 0.07);
$box-shadow-hover: 0px 0px 0.375rem 0px rgba(0, 0, 0, 0.05);
