.swiper-slide .card {
  border-width: 0 0 0 1px;
  border-color: #f0f0f0;
}

.swiper-slide-active .card {
  border-color: transparent;
}

// Articles
.s-articles {
  &__title {
    display: block;
    min-height: 30px;
    margin-bottom: 0.25rem;
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    text-decoration: none;
    &:hover {
      color: $gray-600;
    }
  }

  .card-text,
  .card-link {
    font-size: 12px;
  }
}

// Contact page
.s-contact {
  &__inner {
    display: flex;
    color: $gray-700;
    font-size: 16px;
    font-weight: 600;
  }
  &__title {
    font-weight: 800;
    font-size: 16px;
    margin-bottom: 0.5rem;
  }
  &__icon {
    margin-top: 0.15rem;
    margin-right: 0.5rem;
    color: $primary;
    font-size: 20px;
    width: 1.25em;
    text-align: center;
  }

  &__link.custom-link {
    padding-bottom: 0;
    color: $gray-700;

    &:before {
      bottom: 0.125rem;
    }
  }
}
