.s-products {
  &__label {
    display: block;
    background-color: $primary;
    padding: 0.25rem 0.5rem;
    position: absolute;
    right: 0.5rem;
    top: 1rem;
    font-weight: 500;
    font-size: 13px;
    color: $white;
    line-height: 1;
  }
  &__title {
    display: block;
    margin-bottom: 0.25rem;
    height: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    &:hover {
      color: $gray-600;
    }
  }
  &__model {
    font-weight: 300;
    font-size: 12px;
  }
  &__price {
    font-weight: 800;
    font-size: 17px;
    color: $primary;
  }
  &__old {
    font-size: 15px;
    color: $gray-900;
    text-decoration: line-through;
  }
  &__special,
  &__price {
    line-height: 17px;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      min-height: 34px;
      .s-products__price {
        min-height: auto;
      }
    }
  }
  &__noprice {
    font-weight: bold;
    line-height: 17px;
    @include media-breakpoint-down(sm) {
      line-height: 34px;
    }
  }
  &__btn {
    display: inline-flex;
    align-items: center;

    .icon-magnifying-glass-search {
      margin-bottom: -0.125rem;
    }
  }
  &__icons {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 5;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  &__icon {
    padding: 4px;
    border-radius: 3px;
    color: #000;
    font-size: 20px;
    width: 32px;
    height: 32px;
    display: block;
  }
  &__icon + &__icon {
    margin-top: 8px;
  }
  @include media-breakpoint-down(md) {
    .card_attributes {
      display: none;
    }
  }
}

.s-products {
  .card-img-top {
    text-align: center;
  }
  @include media-breakpoint-up(md) {
    &__item,
    .swiper-slide {
      height: 409px;
      overflow: hidden;
      position: relative;

      &:hover {
        overflow: visible;
        z-index: 2;
      }

      .card {
        min-height: 409px;
      }
    }

    &__item:hover .card,
    .swiper-slide:hover .card {
      -webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
      box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);

      .s-products__icons {
        display: block;
      }
    }
  }
}
