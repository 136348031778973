body {
  min-width: 320px;
}

// Container
.container {
  &,
  &-xxl {
    .container,
    .container-xxl {
      padding: 0;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: $black;

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: 0.5;
  }
}

/* Scrollbar */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: $primary $gray-200;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    background: $gray-200;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: $primary;
    border-radius: 10px;
    width: 4px;
    border-right: 0.5px solid transparent;
    border-left: 0.5px solid transparent;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: darken($primary, 7%);
  }
}

// Content row
#column-left {
  @include media-breakpoint-up(xl) {
    width: 20%;
  }

  ~ #content {
    flex: 1 0 0%;
  }
}

// Title
.module-title {
  margin-bottom: 0.5rem;
  font-weight: 800;
  span {
    color: $primary;
  }
}

// Page
.s-page {
  &__title {
    margin-bottom: 0.375rem;
    font-weight: 800;
    font-size: 24px;
    line-height: 1;
  }

  &__description {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      font-weight: 800;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}

// Load more
.btn-wicon,
.btn-load-more {
  display: inline-flex;
  align-items: center;
  border-style: dotted;
  font-weight: 500;
  font-size: 14px;

  .icon {
    margin-top: -2px;
    margin-bottom: -2px;
    margin-left: 8px;
    width: 18px;
    height: 18px;
    line-height: 20px;
    text-align: center;
  }
}

.btn-load-more.animated .icon {
  -webkit-animation: 0.5s linear rotate infinite;
  animation: 0.5s linear rotate infinite;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// Modal
.modal-header {
  border-bottom: none;
  align-items: normal;
  padding: 24px 16px 12px;

  @include media-breakpoint-up(sm) {
    padding: 32px 40px 12px;
  }
}

.modal-title {
  width: 100%;
  font-weight: bold;
  line-height: 1.2;
}

.modal-body {
  padding: 16px;

  @include media-breakpoint-up(sm) {
    padding: 20px 40px;
  }
}

.modal-footer {
  border-top: none;
  padding: 0 16px 20px;

  @include media-breakpoint-up(sm) {
    padding: 0 40px 32px;
  }
}
@include media-breakpoint-down(md) {
  .modal-footer > * {
    margin: 0;
  }
}

// Breadcrumbs
.breadcrumb-item {
  display: flex;
  align-items: center;
  + .breadcrumb-item::before {
    display: none;
  }
  a {
    color: $gray-900;
    text-decoration: none;
    &:hover {
      color: $gray-600;
    }
  }
  .icon {
    margin-top: 1px;
    margin-right: 0.625rem;
    font-size: 14px;
  }
  span {
    font-weight: bold;
    color: $gray-900;
  }
}

/* Card */
.s-products .card {
  border-width: 0 1px 1px 0;
  border-color: $gray-200;
}

@include media-breakpoint-down(lg) {
  .s-products .col:nth-of-type(2n) .card {
    border-right-color: transparent;
  }
  .s-products .col:nth-of-type(2n + 1):nth-last-of-type(-n + 2) .card,
  .s-products .col:nth-of-type(2n + 1):nth-last-of-type(-n + 2) ~ .col .card {
    border-bottom-color: transparent;
  }
}

@include media-breakpoint-between(lg, xl) {
  .s-products .col:nth-of-type(3n) .card {
    border-right-color: transparent;
  }
  .s-products .col:nth-of-type(3n + 1):nth-last-of-type(-n + 3) .card,
  .s-products .col:nth-of-type(3n + 1):nth-last-of-type(-n + 3) ~ .col .card {
    border-bottom-color: transparent;
  }
}

@include media-breakpoint-up(xl) {
  .s-products .col:nth-of-type(4n) .card {
    border-right-color: transparent;
  }
  .s-products .col:nth-of-type(4n + 1):nth-last-of-type(-n + 4) .card,
  .s-products .col:nth-of-type(4n + 1):nth-last-of-type(-n + 4) ~ .col .card {
    border-bottom-color: transparent;
  }
}
