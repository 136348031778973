.s-footer {
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    background-image: url(/image/footer-image.png);
    background-repeat: no-repeat;
    background-size: 350px auto;
    position: absolute;
    right: -3%;
    top: 0;
    width: 350px;
    height: 100%;
    opacity: 0.5;
  }

  &__contact {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 15px;

    .custom-link {
      color: $white;
    }
  }
  &__icon {
    width: 1.25em;
    margin-right: 0.5rem;
    margin-bottom: 0.75rem;
    font-size: 22px;
    text-align: center;
  }

  &__newsletter {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .h5 {
    margin-bottom: 0.75rem;
    font-weight: 700;
    font-size: 14px;
    color: $gray-600;
    text-transform: uppercase;
  }

  .nav-link {
    display: inline-block;
    margin: 0;
    padding-top: 0;
    font-weight: 400;
    font-size: 13px;
    color: $white;
    line-height: 15px;
    text-decoration: none;
  }

  .btn {
    border: 1px dotted $white;
    color: $white;
    &:hover {
      background-color: $white;
      color: $gray-900;
    }
  }

  .s-sn__link {
    display: block;
    padding: 0;
    width: 36px;
    height: 36px;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
  }
}

.to-top-button {
  position: fixed;
  z-index: 99;
  right: 20px;
  bottom: -100%;
  visibility: hidden;
  opacity: 0;
  transition: color 0.15s ease-in-out, bottom 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;
}

.to-top-button.show {
  visibility: visible;
  opacity: 1;
  bottom: 20px;
}
